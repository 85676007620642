import { Navigate } from "react-router-dom";

import { lazyImport } from "@/utils/lazyImports";

const { Home } = lazyImport(() => import("@/pages/Home"), "Home");
const { Settings } = lazyImport(() => import("@/pages/Settings"), "Settings");
const { UserRecommendation } = lazyImport(
  () => import("@/pages/admin/UserRecommendation"),
  "UserRecommendation"
);
const { Logging } = lazyImport(
  () => import("@/pages/admin/Logging"),
  "Logging"
);
const { UsersList } = lazyImport(
  () => import("@/pages/admin/UsersList"),
  "UsersList"
);
const { UserPortfolio } = lazyImport(
  () => import("@/pages/admin/UserPortfolio"),
  "UserPortfolio"
);

export const protectedRoutes = [
  { path: "/", element: <Home /> },
  { path: "/profile", element: <Settings /> },
  { path: "/admin/users-list", element: <UsersList /> },
  {
    path: "/admin/add-recommendation/:userId",
    element: <UserRecommendation />,
  },
  {
    path: "/admin/user-portfolio/:userId",
    element: <UserPortfolio />,
  },
  { path: "/admin/logging", element: <Logging /> },
  { path: "*", element: <Navigate to="/" /> },
];

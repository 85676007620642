import { Triangle } from "react-loader-spinner";

export const Spinner = () => {
  return (
    <>
      <Triangle
        height="150"
        width="150"
        color="#ffa928"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="triangle-loading"
      />
    </>
  );
};

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import apiClient from '../sanctum/api';
import { browserName, osName  } from "react-device-detect";

var firebaseConfig = {
    apiKey: "AIzaSyD0FBwOhHoLI9ro3A3yrwYQoyJAuDmyc90",
    authDomain: "one-more-bax-18f0a.firebaseapp.com",
    projectId: "one-more-bax-18f0a",
    storageBucket: "one-more-bax-18f0a.appspot.com",
    messagingSenderId: "729890797691",
    appId: "1:729890797691:web:661edb77456264de7e0ade"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

interface FirebaseProps {
  setTokenFound?: any,
  userData?: any,
  userLoading?: boolean,
  data?: any,
  
}

export const fetchToken = ({setTokenFound, data, userData, userLoading}:FirebaseProps) => {

	const browserNameLowerСase = browserName.toLocaleLowerCase()
	const browserAndOS = `${browserNameLowerСase} ${osName}`

	return getToken(messaging, { vapidKey: 'BCRPNITQb58vw0LCAmQNiQ0B5CY_nOd3n9q-qI7131w2iApIjZFgpIpNlMLhFKXtb01XlhGh7MVVEON7XT3EScs' }).then((currentToken) => {
		if (currentToken) {
      setTokenFound(true);
      // console.log("current token for client: ", currentToken);
      // console.log("firebase", data);

      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    
      // if (
      //   data.data.notification_token_needs_to_update === true
      // )
      if (
        osName !== 'ios'
      ) 
        apiClient
          .post(
            `api/v1/user/${data.data.id}/notification`,
            {
              push_notification_token: currentToken,
              user_browser: browserAndOS,
            }
          )
          .then((response) => {
            if (response.status === 204) {
              console.log("token send to backend", {
                push_notification_token: currentToken,
                user_browser: browserAndOS,
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
            } else {
              console.error(error);
            }
          });
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      setTokenFound(false);
      // shows on the UI that permission is required
    }
	}).catch((err) => {
		console.log('An error occurred while retrieving token. ', err);
		// catch error while creating client token
	});
}

export const onMessageListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			resolve(payload);
		});
	});
import { Fragment, useContext, useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../css/loaders/preloader.css";
import "../../css/animations/bounce.scss";
import "../../css/animations/jello.scss";
import "../../css/animations/heartbeat.scss";
import logo from "../../assets/images/logo.png";
import logo_orange from "../../assets/images/logo_orange.png";
import LogoutPage from "./Logout";
import { Dropdown } from "react-bootstrap";
import { ThemeContext } from "../../context/ThemeContext";
import { useStyleMediaQuery } from "../../hooks/useStyleMediaQuery";
import { SCREEN_SM_MIN } from "../../css/breakpoints/breakpoints";

const Header = ({ userData, userLoading }: any) => {
  const { background, changeBackground } = useContext(ThemeContext);

  /////////////////////
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  const [show, setShow] = useState(false);
  ///////////////////////

  const handleThemeMode = () => {
    if (background.value === "dark") {
      changeBackground({ value: "light", label: "Light" });
      localStorage.setItem("colorMode", JSON.stringify("light"));
    } else {
      changeBackground({ value: "dark", label: "Dark" });
      localStorage.setItem("colorMode", JSON.stringify("dark"));
    }
  };

  const { matches: isMobile } = useStyleMediaQuery({
    minOrMax: "max",
    widthOrHeight: "width",
    value: SCREEN_SM_MIN,
  });

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div className="nav-header">
                <Link to="/" className="brand-logo">
                  {background.value === "dark" ? (
                    <Fragment>
                      <img
                        src={logo_orange}
                        className="heartbeat"
                        alt="logo_dark"
                        width="50"
                        height="50"
                      />
                      <h5
                        className="brand-title"
                        style={{ marginTop: "3%", marginLeft: "5%" }}
                      >
                        One More Bax
                      </h5>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {/* Light theme */}
                      <img src={logo} alt="logo_light" width="50" height="50" />
                      <h5
                        className="brand-title"
                        style={{ marginTop: "3%", marginLeft: "5%" }}
                      >
                        One More Bax
                      </h5>
                    </Fragment>
                  )}
                </Link>
              </div>
            </div>
            <ul className="navbar-nav header-right main-notification">
              <li className="nav-item dropdown notification_dropdown">
                {isMobile ? (
                  userLoading ? (
                    ""
                  ) : (
                    <div>
                      {userData.data.api_keys.full ? (
                        ""
                      ) : (
                        <li className="nav-item dropdown notification_dropdown">
                          <Link
                            to={"/profile"}
                            className={`nav-link bell dz-theme-mode`}
                          >
                            <i className="fa fa-btc"></i>
                          </Link>
                        </li>
                      )}
                    </div>
                  )
                ) : (
                  <a
                    href="https://www.bybit.com/invite?ref=O8P9QG"
                    target="__blank"
                    aria-label="Открыть счёт"
                    style={{ marginTop: "7.5%" }}
                  >
                    {userLoading ? (
                      ""
                    ) : (
                      <h4>
                        {userData.data.api_keys.full ? "" : "Открыть счёт"}
                      </h4>
                    )}
                  </a>
                )}
              </li>

              {document.fullscreenElement ? (
                <li
                  className="nav-item dropdown notification_dropdown"
                  onClick={() => document.exitFullscreen()}
                >
                  <Link
                    to={"#"}
                    className={`nav-link bell dz-theme-mode ${
                      background.value === "light" ? "active" : ""
                    }`}
                  >
                    <i id="icon-light" className="las la-compress"></i>
                    <i id="icon-dark" className="las la-compress"></i>
                  </Link>
                </li>
              ) : (
                <li
                  className="nav-item dropdown notification_dropdown"
                  onClick={() => document.body.requestFullscreen()}
                >
                  <Link
                    to={"#"}
                    aria-label="Fullscreen"
                    className={`nav-link bell dz-theme-mode ${
                      background.value === "light" ? "active" : ""
                    }`}
                  >
                    <i id="icon-light" className="las la-expand"></i>
                    <i id="icon-dark" className="las la-expand"></i>
                  </Link>
                </li>
              )}

              <li
                className="nav-item dropdown notification_dropdown"
                onClick={() => handleThemeMode()}
              >
                <Link
                  to={"#"}
                  aria-label="Theme mode"
                  className={`nav-link bell dz-theme-mode ${
                    background.value === "light" ? "active" : ""
                  }`}
                >
                  <i id="icon-light" className="fa fa-sun-o bounce"></i>
                  <i id="icon-dark" className="fa fa-moon-o bounce"></i>
                </Link>
              </li>

              <Dropdown
                show={show}
                as="li"
                className="nav-item dropdown header-profile"
              >
                {userLoading ? (
                  <span
                    className="preloader"
                    style={{ marginLeft: "1rem" }}
                  ></span>
                ) : (
                  <Dropdown.Toggle
                    onClick={() => setShow(!show)}
                    variant=""
                    as="a"
                    className="nav-link i-false c-pointer"
                    role="button"
                    data-toggle="dropdown"
                  >
                    <div className="header-info">
                      {/* name */}
                      <span>{userData.data.name}</span>
                      {/* role */}
                      {/* <small style={{ textTransform: "capitalize" }}>
                        {userData.data.role}
                      </small> */}
                    </div>
                  </Dropdown.Toggle>
                )}
                <Dropdown.Menu
                  ref={ref}
                  align="right"
                  className="mt-2"
                  style={{ zIndex: "9999" }}
                >
                  <div className="dropdown-item ai-icon">
                    <NavLink
                      onClick={() => setShow(!show)}
                      to="/"
                      className={({ isActive }) =>
                        isActive
                          ? "NavItemHeader NavLinkActive"
                          : "NavItemHeader NavLink"
                      }
                    >
                      <i className="fa fa-briefcase"></i>
                      <span className="ml-2" style={{ fontWeight: "600" }}>
                        Главная
                      </span>
                    </NavLink>
                  </div>

                  <div className="dropdown-item ai-icon">
                    <NavLink
                      onClick={() => setShow(!show)}
                      to="/profile"
                      className={({ isActive }) =>
                        isActive
                          ? "NavItemHeader NavLinkActive"
                          : "NavItemHeader NavLink"
                      }
                    >
                      <i className="fa fa-user"></i>
                      <span className="ml-2" style={{ fontWeight: "600" }}>
                        Мой профиль
                      </span>
                    </NavLink>
                  </div>
                  {userLoading ? (
                    ""
                  ) : userData.data.is_admin ? (
                    <>
                      <hr />
                      <div className="dropdown-item ai-icon">
                        <NavLink
                          onClick={() => setShow(!show)}
                          to="/admin/users-list"
                          className={({ isActive }) =>
                            isActive
                              ? "NavItemHeader NavLinkActive"
                              : "NavItemHeader NavLink"
                          }
                        >
                          <i className="fa fa-eye"></i>
                          <span className="ml-2" style={{ fontWeight: "600" }}>
                            Администрирование
                          </span>
                        </NavLink>
                      </div>
                      <div className="dropdown-item ai-icon">
                        <NavLink
                          onClick={() => setShow(!show)}
                          to="/admin/logging"
                          className={({ isActive }) =>
                            isActive
                              ? "NavItemHeader NavLinkActive"
                              : "NavItemHeader NavLink"
                          }
                        >
                          <i className="fa fa-list"></i>
                          <span className="ml-2" style={{ fontWeight: "600" }}>
                            Логирование
                          </span>
                        </NavLink>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <hr />

                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>

        {/* SUB HEADER */}
        {/* <div className="sub-header">
          <div className="d-flex align-items-center flex-wrap mr-auto">
            <h5 className="dashboard_bar">Dashboard</h5>
          </div>
          <div className="d-flex align-items-center">
            <Link to={"#"} className="btn btn-xs btn-primary light mr-1">Today</Link>
						<Link to={"#"} className="btn btn-xs btn-primary light mr-1">Month</Link>
						<Link to={"#"} className="btn btn-xs btn-primary light">Year</Link>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Header;

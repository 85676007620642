import React, { createContext, useEffect, useState } from "react";

export const ThemeContext = createContext();

const ThemeContextProvider = (props) => {
  const [background, setBackground] = useState({
    value: JSON.parse(localStorage.getItem("colorMode")) || "dark",
    label: "Dark",
  });
  const body = document.querySelector("body");

  const changeBackground = (name) => {
    body.setAttribute("data-theme-version", name.value);
    setBackground(name);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.setAttribute(
      "data-theme-version",
      JSON.parse(localStorage.getItem("colorMode")) || "dark"
    );
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        changeBackground,
        background,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
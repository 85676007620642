import React from "react";
import clsx from 'clsx';
import '../../../css/animations/bounce.scss'

const variants = {
  primary: "",
  login: "mt-5",
  registration: "mt-5",
};


export type AuthCardProps = React.ButtonHTMLAttributes<HTMLBodyElement> & {
	variant?: keyof typeof variants;
};

export const AuthCard = React.forwardRef<HTMLBodyElement, AuthCardProps>(
	(
		{
			variant = 'primary',
			className,
			...props
		},
		ref
	) => {
		return (

        <div className="authincation h-100 p-meddle">
			<div className="container h-100">
				<div className="row justify-content-center align-items-center">
					<div
					className={clsx(
						'col-md-10 col-lg-8 col-xl-8',
					)}
					>
						<div className={clsx("authincation-content bounce",  variants[variant],)}>
							<div className="row no-gutters">
								<div className="col-xl-12">
									<div className="auth-form">

                                    {props.children}

                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
     );
}
);
AuthCard.displayName = 'AuthCard';
import "./css/style.css";
import { AppProvider } from "./providers/app";
import { AppRoutes } from "./routes";
import React from "react";


function App() {
  return (
    <React.Fragment>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </React.Fragment>
  );
}

export default App;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { OMBApi } from "../api/ApiOMB";
import userReducer from '../reducers/userSlice';
import filtersReducer from '../reducers/filtersSlice';


const rootReducer = combineReducers({
	[OMBApi.reducerPath]: OMBApi.reducer,
	userReducer,
	filtersReducer
})

export const setupStore = () => {
	return configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(OMBApi.middleware),
	})
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
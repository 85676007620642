import { lazyImport } from "@/utils/lazyImports";
import { Navigate } from "react-router-dom";

const { Login } = lazyImport(() => import("@/pages/Login"), "Login");
const { Registration } = lazyImport(
  () => import("@/pages/Registration"),
  "Registration"
);
const { ResetPassword } = lazyImport(
  () => import("@/pages/ResetPassword"),
  "ResetPassword"
);

export const publicRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/registration", element: <Registration /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "*", element: <Navigate to="/login" /> },
];

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../models/IUser"

const userToken = localStorage.getItem('userToken')
	? localStorage.getItem('userToken')
	: null

const authLogic = () => {
	return userToken ? true : false
}

interface UserState {
	users: IUser[];
	isLoading: boolean;
	error: string;
	isAuth: boolean;
	userToken: any;
}

const initialState: UserState = {
	users: [],
	isLoading: false,
	error: '',
	isAuth: authLogic(),
	userToken,
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		authTrue: (state = initialState, action: PayloadAction<boolean>) => {
			
			localStorage.setItem('userToken', 'Sanctum')
			state.isAuth = true;
		},
		authFalse: (state = initialState, action: PayloadAction<boolean>) => {
			state.isAuth = false;
			localStorage.removeItem('userToken')
			window.location.reload()
		}
	},

	
	
})

export const { authTrue, authFalse } = userSlice.actions;



export default userSlice.reducer;
import { NavLink } from "react-router-dom";

function MobileFooter({ userData, userLoading }: any) {
  return (
    <div className="NavWrapper">
      <div className="NavBar">
        <ul className="NavList">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "NavItem NavLinkActive" : "NavItem NavLink"
            }
          >
            <li>
              <i className="flaticon-381-briefcase"></i>
            </li>
          </NavLink>
          {userLoading ? (
            ""
          ) : userData.data.is_admin ? (
            <>
              <NavLink
                to="/admin/users-list"
                className={({ isActive }) =>
                  isActive ? "NavItem NavLinkActive" : "NavItem NavLink"
                }
              >
                <li>
                  <i className="flaticon-381-user-9"></i>
                </li>
              </NavLink>
              {/* <div className="NavItem NavLink" style={{ color: "gray" }}>
                <li>
                  <i className="flaticon-381-view-2"></i>
                </li>
              </div> */}
              <NavLink
                to="/admin/logging"
                className={({ isActive }) =>
                  isActive ? "NavItem NavLink_active" : "NavItem NavLink"
                }
              >
                <li>
                  <i className="fa fa-list"></i>
                </li>
              </NavLink>
            </>
          ) : (
            ""
          )}

          <NavLink
            to="/profile"
            className={({ isActive }) =>
              isActive ? "NavItem NavLink_active" : "NavItem NavLink"
            }
          >
            <li>
              <i className="flaticon-381-controls-7"></i>
            </li>
          </NavLink>
        </ul>
      </div>
    </div>
  );
}

export default MobileFooter;

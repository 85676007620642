import { authFalse } from "@/redux/reducers/userSlice";
import React from "react";
import { useDispatch } from "react-redux";
import apiClient from "../../services/sanctum/api";

function LogoutPage() {
  const dispatch = useDispatch();

  function onLogout() {
    apiClient.get("/sanctum/csrf-cookie").then((response) => {
      apiClient
        .post("/api/v1/logout", {})
        .then((response) => {
          // LOGOUT STATUS?
          if (response.status === 204) {
            dispatch(authFalse(true));
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            alert(error);
          } else {
            console.error(error);
          }
        });
    });
    // window.location.reload();
  }
  return (
    <>
      <div
        className="dropdown-item ai-icon"
        style={{ cursor: "pointer" }}
        onClick={onLogout}
      >
        <i
          className="fa fa-sign-out"
          style={{ color: "#ed4545", fontWeight: "600", fontSize: "18px" }}
        ></i>
        <span className="ml-2" style={{ fontWeight: "600", fontSize: "18px" }}>
          Выйти
        </span>
      </div>
    </>
  );
}

export default LogoutPage;

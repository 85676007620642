import { useState, useEffect } from 'react'

interface MediaQueryProps {
	minOrMax: any,
	widthOrHeight: any,
	value: any
}

export const useStyleMediaQuery = ({ minOrMax, widthOrHeight, value }:MediaQueryProps) => {

	if (!minOrMax) minOrMax = 'min';
	if (!widthOrHeight) widthOrHeight = 'width';



	const [matches, setMatches] = useState(
		typeof window !== "undefined" && window.matchMedia(`(${minOrMax}-${widthOrHeight}: ${value}px)`).matches
	)

	useEffect(() => {
		window
			.matchMedia(`(${minOrMax}-${widthOrHeight}: ${value}px)`)
			.addEventListener('change', e => setMatches(e.matches));
	}, [minOrMax, widthOrHeight, value]);

	return { matches }

}
import { useAppSelector } from "@/hooks/redux";
import { useRoutes } from "react-router-dom";

import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";
import ScrollToTop from "../utils/ScrollToTop";
import Footer from "@/layouts/Footer/Footer";
import UserContext from "@/context/UserContext";
import { useContext } from "react";
import Header from "@/layouts/nav/Header";

export const AppRoutes = () => {
  const { isAuth } = useAppSelector((state) => state.userReducer);
  const { userData, userLoading }: any = useContext(UserContext);

  const routes = isAuth ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes]);

  return (
    <>
      <div
        id={`${isAuth ? "main-wrapper" : ""}`}
        className={`${isAuth ? "show" : "mh100vh"}`}
      >
        {/* @ts-ignore */}
        {isAuth && <Header userData={userData} userLoading={userLoading} />}

        <div className={`${isAuth ? "content-body" : ""}`}>
          <div
            className={`${isAuth ? "container-fluid" : ""}`}
            style={isAuth ? { minHeight: window.screen.height - 60 } : {}}
          >
            {element}
          </div>
        </div>
        {/* @ts-ignore */}
        {isAuth && <Footer userData={userData} userLoading={userLoading} />}
      </div>

      <ScrollToTop />
    </>
  );
};

import { createContext, useState, useEffect } from "react";
import apiClient from "../services/sanctum/api";
import { useAppSelector } from "@/hooks/redux";
import { authFalse } from "@/redux/reducers/userSlice";
import { useDispatch } from "react-redux";
import { fetchToken } from "../services/firebase/firebase";

const UserContext = createContext("");

export function UserProvider({ children }: any) {
  const [isTokenFound, setTokenFound] = useState<boolean>(false);

  const { isAuth } = useAppSelector((state) => state.userReducer);
  const [userData, setUserData] = useState<any>([]);
  const [userLoading, setUserLoading] = useState<any>(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuth)
      apiClient
        .get("api/v1/user/params")
        .then(({ data }) => {
          setUserData(data);
          setUserLoading(false);
          fetchToken({
            setTokenFound,
            data,
            userData,
          });
        })
        .catch((error) => {
          apiClient.get("/sanctum/csrf-cookie").then((response) => {
            apiClient
              .post("/api/v1/logout", {})
              .then((response) => {
                if (response.status === 204) {
                  dispatch(authFalse(true));
                }
              })
              .catch((error) => {
                if (error.response && error.response.status === 422) {
                  alert(error);
                } else {
                  console.error(error);
                }
              });
          });
        });
    // eslint-disable-next-line
  }, [isAuth]);

  return (
    <UserContext.Provider
      // @ts-ignore
      value={{
        userData,
        setUserData,
        userLoading,
        setUserLoading,
        setTokenFound,
        isTokenFound,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
export default UserContext;

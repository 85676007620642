import React from "react";
import { SCREEN_LG_MIN } from "../../css/breakpoints/breakpoints";
import { useStyleMediaQuery } from "../../hooks/useStyleMediaQuery";
import MobileFooter from "./MobileFooter";
import "../../css/footer.scss";

const Footer = ({ userData, userLoading }: any) => {
  const { matches: isMobile } = useStyleMediaQuery({
    minOrMax: "max",
    widthOrHeight: "width",
    value: SCREEN_LG_MIN,
  });

  // var date = new Date();
  return (
    <>
      {isMobile ? (
        <div style={{ height: "20vh" }}>
          <MobileFooter userData={userData} userLoading={userLoading} />
        </div>
      ) : (
        <div className="footer mt-5">
          <div className="copyright">
            {/* <p>OneMoreCrypto, {date.getFullYear()} © Все права защищены.</p> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;

import { Spinner } from "@/components/Elements";

export const ErrorFallback = () => {
  return (
    <div
      className="justify-content-center"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
      role="alert"
    >
      <Spinner />
    </div>
  );
};

import { createContext, useState } from "react";

const ModalsContext = createContext("");

export function ModalsProvider({ children }: any) {
  const [keyIsHere, setKeyIsHere] = useState<any>(false);



  const [modalCentered, setModalCentered] = useState<any>(false);
  const [modalFinal, setModalFinal] = useState<any>(false);
  const [modalUserBalance, setModalUserBalance] = useState<any>(false);

  return (
    <ModalsContext.Provider
      // @ts-ignore
      value={{
        modalCentered,
        setModalCentered,
        modalFinal,
        setModalFinal,
        keyIsHere,
        setKeyIsHere,
        modalUserBalance,
        setModalUserBalance,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
}

export default ModalsContext;

import * as React from 'react';
// refactor to native
import { ErrorBoundary } from 'react-error-boundary';

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import ThemeContextProvider from '@/context/ThemeContext';
import '../css/main_loader.scss'

import { ModalsProvider } from '@/context/ModalsContext';
import { UserProvider } from "@/context/UserContext";
import { ErrorFallback } from "./ErrorFallback";

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div className="main_loader">
          <Spinner />
        </div>
      }
    >
      <UserProvider>
        <ThemeContextProvider>
          <ModalsProvider>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <HelmetProvider>
                <Router>{children}</Router>
              </HelmetProvider>
            </ErrorBoundary>
          </ModalsProvider>
        </ThemeContextProvider>
      </UserProvider>
    </React.Suspense>
  );
};
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface UserState {
	checkedBoxes: any;

}

const initialState: UserState = {
	checkedBoxes: true,

}

export const filtersSlice = createSlice({
	name: 'checkedBoxes',
	initialState,
	reducers: {
		checkedBoxesAC: (state = initialState, action: PayloadAction<boolean>) => {
			state.checkedBoxes = action.payload
		},
		
	},

	
	
})

export const { checkedBoxesAC } = filtersSlice.actions;



export default filtersSlice.reducer;
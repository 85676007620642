import axios from 'axios';
import { REACT_APP_API_HOST } from "@/config/config";

const apiClient = axios.create({
  baseURL: REACT_APP_API_HOST,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
});

export default apiClient;